import React, { useState, useEffect, useRef } from "react";
import { CardLotModel } from "../Catalog/types"
import CardLot from "../Catalog/CardLot"
import CardLotCloseSales from "../Catalog/CardLotCloseSales"
import { fetcherPOSTJSON } from "../Common/Fetch";
import history from "../history";
import { FilterModel } from "./types"
import { AuctionTypeSearchType, TradeTypeSearch, TimeSearchType } from "../Common/types"
import { DEFAULT_LOT_IMAGE, LOT_DETAILS_URL } from "../settings";


function NearestLots() {
    const [lots, setLots] = useState<Array<CardLotModel>>([]);
    const [visibleLotsCount, setVisibleLotsCount] = useState(
        () => {
            if (window.innerWidth <= 820) {
                return 2;
            } else if (window.innerWidth <= 1250) {
                return 4;
            } else {
                return 6;
            }
        }
    );
    const [filters, setFilters] = useState<FilterModel>(
        {
            CategoryId: 0,
            CurrentPage: 1,
            CountLotsOnPage: 6,
            AuctionType: 0,
            SalesType: 0,
            TimeSearch: 1,
            StartDate: null,
            EndDate: null,
            StartPrice: 0,
            EndPrice: 0,
            TextSearch: "",
            dateInputLocale: null
        }
    );

    const [isShowAll, setIsShowAll] = useState(false);
    const [scrollTranslate, setScrollTranslate] = useState(0);
    const lotItem = useRef(null);
    const [lotItemWidth, setLotITemWidth] = useState<number | null>(null);

    // Устанавливает ширину блока лота
    useEffect(() => {
        if (lotItem.current) {
            const width = lotItem.current.getBoundingClientRect().width;
            setLotITemWidth(width);
            console.log(width);
        }

        console.log(lots.length);
    }, [lots]);


    useEffect(() => {
        console.log(filters);
        getListLots();
    }, [filters])

    // Получение лотов
    const getListLots = () => {
        setScrollTranslate(0);
        var json = JSON.stringify(filters);
        console.log('filters = ', json);
        fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
            if (result.Success == true) {
                setLots(result.lots);
            }
            else {
                console.log('error!');
            }
        });
    }

    // Показ большего кол-ва лотов
    useEffect(() => {
        if (isShowAll) {
            setFilters({ ...filters, CountLotsOnPage: 20 });
        } else {
            setFilters({ ...filters, CountLotsOnPage: visibleLotsCount });
            setScrollTranslate(0);
        }
    }, [isShowAll])

    const onIsFavoriteToggle = () => {
        
    }


    const scrollNext = () => {
        if (scrollTranslate == (-(lotItemWidth + 20) * (lots.length - visibleLotsCount) / 2)) {
            setScrollTranslate(0);
        } else {
            setScrollTranslate(scrollTranslate - lotItemWidth - 20);
        }
    }

    const scrollPrev = () => {
        if (scrollTranslate == 0) {
            setScrollTranslate(-(lotItemWidth + 20) * (lots.length - visibleLotsCount) / 2);
        } else {
            setScrollTranslate(scrollTranslate + lotItemWidth + 20);
        }
    }

    return (
        <>
            <h2>Ближайшие торги</h2>
            <div className="scrollWrapper">
                {(isShowAll && lots.length > visibleLotsCount) &&
                    <button className="scrollButton scrollButtonPrev" onClick={scrollPrev}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 40 0)" fill="#8D8D8D" />
                            <path d="M14.193 19.9997C14.1918 21.1747 14.6449 22.3094 15.4668 23.1892C15.9597 23.7141 16.439 24.2117 16.7829 24.5376L21.5662 29.1493C21.7194 29.3096 21.9051 29.4389 22.1123 29.5295C22.3195 29.6201 22.5439 29.6701 22.772 29.6764C23.0001 29.6828 23.2272 29.6454 23.4397 29.5665C23.6522 29.4876 23.8456 29.3689 24.0085 29.2173C24.1713 29.0658 24.3001 28.8847 24.3873 28.6849C24.4744 28.485 24.518 28.2705 24.5155 28.0543C24.513 27.838 24.4644 27.6245 24.3727 27.4265C24.2809 27.2285 24.1479 27.0501 23.9816 26.902L19.1898 22.2791C18.873 21.9773 18.4428 21.5278 18.0041 21.0607C17.7312 20.7675 17.5806 20.3899 17.5806 19.9989C17.5806 19.6079 17.7312 19.2303 18.0041 18.937C18.4411 18.4715 18.8714 18.0221 19.1779 17.7299L23.9816 13.0974C24.1479 12.9493 24.2809 12.7709 24.3727 12.5729C24.4644 12.3749 24.513 12.1614 24.5155 11.9451C24.518 11.7289 24.4744 11.5144 24.3873 11.3145C24.3001 11.1146 24.1713 10.9335 24.0085 10.782C23.8456 10.6305 23.6522 10.5118 23.4397 10.4329C23.2272 10.354 23.0001 10.3166 22.772 10.323C22.5439 10.3293 22.3195 10.3793 22.1123 10.4699C21.9051 10.5604 21.7194 10.6898 21.5662 10.8501L16.7744 15.4714C16.4357 15.7925 15.9614 16.2853 15.4719 16.8086C14.6474 17.6878 14.1923 18.8234 14.193 19.9997Z" fill="white" />
                        </svg>
                    </button>
                }
                <div className="nearestLots"
                    style={{
                        transform: `translateX(${scrollTranslate}px)`,
                        width: lots.length > 0 ? (lotItemWidth + 20) * lots.length / 2 : 'max-content'
                    }}
                >
                    {lots.length > 0 ? lots.map(lot => (
                        <div ref={lotItem} key={lot.LotId} className="nearestLot">
                            <a href={lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId} data-attribute-URL_={lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId}>
                                {(lot.ImagePath && lot.ImagePath.length > 0) ?
                                    <img key={lot.ImageId} src={lot.FullImagePath} alt={lot.ImageName} />
                                    :
                                    <img src="../../main-images/defaultLotImage.png" alt="Картинка по умолчанию" />
                                }
                            </a>
                            <div className="lotInfo">
                                <h4>{lot.Price}</h4>
                                <p className="defaultDescription">{lot.LotName}</p>
                            </div>
                        </div>
                    )) : <p className="defaultDescription">К сожалению, ближайших лотов не найдено</p>}
                </div> 
                {(isShowAll && lots.length > visibleLotsCount) &&
                    <button className="scrollButton scrollButtonNext" onClick={scrollNext}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect width="40" height="40" rx="20" fill="#8D8D8D" />
                            <path d="M25.807 19.9997C25.8082 21.1747 25.3551 22.3094 24.5332 23.1892C24.0403 23.7141 23.561 24.2117 23.2171 24.5376L18.4338 29.1493C18.2806 29.3096 18.0949 29.4389 17.8877 29.5295C17.6805 29.6201 17.4561 29.6701 17.228 29.6764C16.9999 29.6828 16.7728 29.6454 16.5603 29.5665C16.3478 29.4876 16.1544 29.3689 15.9915 29.2173C15.8287 29.0658 15.6999 28.8847 15.6127 28.6849C15.5256 28.485 15.482 28.2705 15.4845 28.0543C15.487 27.838 15.5356 27.6245 15.6273 27.4265C15.7191 27.2285 15.8521 27.0501 16.0184 26.902L20.8102 22.2791C21.127 21.9773 21.5572 21.5278 21.9959 21.0607C22.2688 20.7675 22.4194 20.3899 22.4194 19.9989C22.4194 19.6079 22.2688 19.2303 21.9959 18.937C21.5589 18.4715 21.1286 18.0221 20.8221 17.7299L16.0184 13.0974C15.8521 12.9493 15.7191 12.7709 15.6273 12.5729C15.5356 12.3749 15.487 12.1614 15.4845 11.9451C15.482 11.7289 15.5256 11.5144 15.6127 11.3145C15.6999 11.1146 15.8287 10.9335 15.9915 10.782C16.1544 10.6305 16.3478 10.5118 16.5603 10.4329C16.7728 10.354 16.9999 10.3166 17.228 10.323C17.4561 10.3293 17.6805 10.3793 17.8877 10.4699C18.0949 10.5604 18.2806 10.6898 18.4338 10.8501L23.2256 15.4714C23.5643 15.7925 24.0386 16.2853 24.5281 16.8086C25.3526 17.6878 25.8077 18.8234 25.807 19.9997Z" fill="white" />
                        </svg>
                    </button>
                }
            </div>

            {lots.length > 0 &&
                <button className="pinkBorderButton" onClick={() => { setIsShowAll(!isShowAll) }}>
                    {isShowAll ? 'Скрыть' : 'Все ближайшие лоты'}
                </button>
            }
        </>
    );
}

export default NearestLots;

